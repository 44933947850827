<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Reparatur-service <br />
    </h2>
    <article class="container mx-auto p-4 md:p-32">
      <h2 class="text-5xl mb-16" style="color: #f27405">
        Fachgerechte Rollladen-Reparatur in Berlin
      </h2>
      <div class="flex flex-col">
        <p class="w-full md:pl-10 md:pb-10 text-gray-600">
          Anstatt einen defekten Rollladen sofort auszutauschen, ist eine
          Reparatur oftmals die lohnendere Alternative. Und da kommen wir in
          Berlin ins Spiel: Unsere Spezialisten kümmern sich um Ihre
          Rollladen-Reparatur. Im Herzen der Hauptstadt sind wir Ihr
          Meisterbetrieb mit Produkten von Markenherstellern und einem
          hervorragenden Kundenservice.
        </p>
        <div class="w-full">
          <p class="text-2xl md:pl-10 pb-3 underline" style="color: #f27405">
            Von der Rolladen-Reparatur bis zur Ausbesserung Ihres Terrassendachs
            – nutzen Sie unseren Reparatur-Service in Berlin für:
          </p>
          <div class="md:pl-10 md:pb-10 text-gray-800 leading-relaxed">
            <a href="rollladen"
              ><i class="fas fa-check" style="color: #f27405"></i> Jede Art von
              Rolläden</a
            ><br />
            <a href="#"
              ><i class="fas fa-check" style="color: #f27405"></i> Jede Art von
              Markisen</a
            ><br />
            <a href="/rolltor-rollgitter-garagentor"
              ><i class="fas fa-check" style="color: #f27405"></i> Rolltore</a
            ><br />
            <a href="/jalousien-aussenraffstores"
              ><i class="fas fa-check" style="color: #f27405"></i> Raffstoren</a
            ><br />
            <a href="/zipscreen"
              ><i class="fas fa-check" style="color: #f27405"></i> ZIP-Screen</a
            ><br />
            <a href="#"
              ><i class="fas fa-check" style="color: #f27405"></i>
              Innenliegender Sonnenschutz</a
            ><br />
            <a href="#"
              ><i class="fas fa-check" style="color: #f27405"></i>
              Insektenschutz</a
            ><br />
            <a href="#"
              ><i class="fas fa-check" style="color: #f27405"></i>
              Steuerungen</a
            ><br />
          </div>
        </div>
      </div>
      <p class="md:pl-10 md:pb-10 text-gray-600">
        <span class="text-2xl" style="color: #f27405"
          >Unser Reparatur-Service für unser Kunden im Raum Berlin:</span
        ><br />
        Wir von BE-RO-MA bieten Ihnen professionelle Reparaturen aller Art.
        Egal, ob Rollladen oder Markise, Terrassendach oder Jalousien. Eine
        Reparatur durch unsere Fachkräfte in Berlin ist in vielen Fällen vor Ort
        möglich. Andernfalls steht uns für komplexere Aufgaben unsere hauseigene
        Werkstatt zur Verfügung. Außerdem garantieren wir Ihnen einen Termin
        ohne lange Wartezeit! <br /><br />
      </p>
      <contacticons></contacticons>
      <div class="flex flex-col md:pl-10 my-12">
        <h3 class="text-2xl my-4 font-bold" style="color: #f27405">
          Unsere Reparatur-Service
        </h3>
        <ul class="flex flex-col lg:flex-row justify-center items-center my-4">
          <div class="lg:w-1/3">
            <h3 class="text-lg my-4 font-semibold" style="color: #f27405">
              Rollladen-Reparatur
            </h3>
            <img
              class="h-96"
              src="../assets/img/service/beroma-vorbaurollladen-insektenschutz.webp"
              alt="berliner vorbaurolladen insektenschutz"
            />
          </div>
          <p class="lg:w-2/3 px-5 my-4">
            <span class="text-lg font-bold" style="color: #033859"
              >Einen Rollladen reparieren? BE-RO-MA in Berlin macht das mit
              links!</span
            ><br /><br />
            Wenn Sie Ihren Rollladen reparieren lassen müssen, sollten Sie uns
            als Ihren Fachanbieter in Berlin beauftragen. Ganz egal, ob defekter
            Motor oder eingerissener Gurt: Unsere Fachleute sind absolute
            Experten auf Ihrem Gebiet – und sie finden für nahezu jedes Problem
            die richtige Lösung. Wenn es darum geht, einen Rollladen zu
            reparieren, sind wir in und um Berlin Ihre Anlaufstelle Nummer eins!
            Wir führen unsere Reparaturen einfach und bequem direkt bei Ihnen zu
            Hause durch. Rufen Sie uns einfach an und wir sind zeitflexibel bei
            Ihnen vor Ort. Sollte eine Rollladen-Reparatur bei einer ersten
            Ansicht nicht möglich sein, gehen wir dem Defekt in unserer
            hauseigenen Werkstatt in Berlin auf den Grund. Und natürlich können
            wir Ihnen auch ein hochwertiges Rollladen-Modell aus unserem
            Sortiment empfehlen, sollte sich eine Reparatur nun wirklich nicht
            mehr lohnen.
          </p>
        </ul>
        <div class="flex flex-col lg:flex-row justify-center items-center my-4">
          <div class="lg:w-1/3">
            <h3 class="text-lg my-4 font-semibold" style="color: #f27405">
              Jalousien-Reparatur
            </h3>
            <img
              class="h-72"
              src="../assets/img/projekte/projekt9.webp"
              alt="berliner vorbaurolladen reparatur"
            />
          </div>
          <p class="lg:w-2/3 px-5 my-4 text-gray-600">
            Ihre Innen oder Außenjalousie ist kaputt? Sie können es nicht mehr
            bedienen? Kein Problem!
            <span class="font-bold" style="color: #f27405"
              >Rufen Sie uns jetzt an und sichern Sie sich Ihren Termin</span
            >. <br /><br />
            Zur einer Fachgerechten Reparatur gehört nicht nur das Fachwissen
            dazu, sondern auch die Ausstattung und die Erfahrung. Wir reparieren
            und reinigen Ihre Jalousien und montieren Ihnen es Ordnungsgemäß
            wieder, sodass es aussieht wie neu.
          </p>
        </div>
        <div class="flex flex-col lg:flex-row justify-center items-center my-4">
          <figure class="lg:w-1/3">
            <figcaption
              class="text-lg my-4 font-semibold"
              style="color: #f27405"
            >
              Markisen-Reparatur
            </figcaption>
            <img
              class="h-96"
              src="../assets/img/landingpage/beroma-senkrecht-markisen-reparatur-2.webp"
              alt="rolladen reparatur mti kran"
            />
          </figure>
          <p class="lg:w-2/3 px-5 my-4 text-gray-600">
            <span class="text-lg font-bold" style="color: #033859"
              >Lassen Sie Markisen nur von Fachkräften reparieren – BE-RO-MA in
              Berlin</span
            ><br /><br />
            Neben der Rollladen-Reparatur sind wir außerdem für Sie da, wenn
            eine Ihrer Markisen nicht mehr funktioniert. Auch hier prüfen und
            reparieren wir für Sie in Berlin vor Ort! Ob gerissenes Tuch,
            kaputter Motor, defekte Gelenkarm-Markisen oder Komplikationen bei
            der Einstellung: Wir reparieren fachgerecht mit Garantie! Für jedes
            Problem finden unsere Fachleute in Berlin eine sichere Lösung.
            Sichern Sie sich jetzt Ihren Termin über
            <a
              target="_blank"
              href="https://wa.me/+4917680834261"
              style="color: #f27405"
              >WhatsApp</a
            >! Oder rufen Sie uns einfach an!
          </p>
        </div>
      </div>
    </article>
  </section>
</template>
<script>
import contacticons from "../components/contact";
export default {
  name: "App",
  components: {
    contacticons,
  },
};
</script>
